import React from "react"
import styled from "styled-components"
import { blue, black } from "@vschool/lotus"

const BulletsContainer = styled.div`
  margin-top: 48px;

  & div:nth-child(6) {
    border-bottom: 2px solid ${blue.base};
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 408px 408px;

    & div:nth-child(odd) {
      margin-right: 34px;
    }

    & div:nth-child(5) {
      border-bottom: 2px solid ${blue.base};
    }
  }
`

const Bullet = styled.div`
  border-top: 2px solid ${blue.base};
  padding: 8px 0;

  @media (min-width: 1200px) {
  }
`

const BulletText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${black};
`

export default function Bullets(props) {
  const { bullets } = props
  return (
    <BulletsContainer>
      {bullets.map((bullet) => (
        <Bullet key={bullet}>
          <BulletText>{bullet}</BulletText>
        </Bullet>
      ))}
    </BulletsContainer>
  )
}
